<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Edit Loyalty Fee</span>
    </v-card-title>
      <v-container>
       <input-label>
          New Loyalty Fee
        </input-label>
        <v-text-field
          v-model="newPrice"
          label="new Loyalty Fee"
          placeholder="Cannot be greater than 30 "
          prefix = "%"
          flat
          solo
          :rules="[rules.notInvalid]"
          outlined
          type ="number"
        ></v-text-field>
            <v-btn
        class="mr-4"
        :disabled="!passValidation"
        @click="submit"
      >
        Update
      </v-btn>
      <v-btn @click="close">
        close
      </v-btn>
      </v-container>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BigNumber from 'bignumber.js';
// import formValidator from '@/lib/formValidators/formValidator';
import itemAPI from '@/api/item';

export default {
  name: 'loyaltyFeeForm',
  props: {
    id: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    newPrice: '0',
    errorMessages: {
      valueMsg: '',
    },
    rules: { // rules for user input
      notInvalid: (value) => { // check if value is number
        const num = parseInt(value, 10);
        return (!Number.isNaN(num) && (num >= 0 && num <= 30)) || 'Invalid Number';
      },
    },
  }),
  computed: {
    ...mapGetters({ token: 'user/token' }),
    currencySymbol() {
      return this.currency.toUpperCase();
    },
    passValidation() { // check validation
      const pass = typeof this.rules.notInvalid(this.newPrice) !== 'string';
      return pass;
    },
  },
  methods: {
    ...mapActions({
      alert: 'alert/callErrorMessage',
    }),
    submit() {
      itemAPI.setLoyaltyFee(this.token, this.newPrice, this.id).then((res) => {
        console.log(res);
        this.$router.go();
      }).catch((err) => {
        console.log(err);
        // add alert to system
      });
    },
    close() { // need to reset state
      this.newPrice = '0';
      this.$emit('close');
      // emit close
    },
    netPrice() {
      let calculatedVal = BigNumber(this.newPrice);
      calculatedVal = calculatedVal.multipliedBy(1000000000); // convert to eth
      return calculatedVal.toString();
    },
  },
};
</script>
